/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/bootstrap@4.6.2/dist/js/bootstrap.min.js
 * - /npm/handlebars@4.7.8/dist/handlebars.min.js
 * - /npm/moment@2.30.1/moment.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
